// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import $ from "jquery";
import Chart from 'chart.js';

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
// Action Text
require("trix");
require("@rails/actiontext");
// stylesheets
require("../stylesheets/application.scss");
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import patchLikeLinks from "./common/like_requester";

// images
require.context("../images", true)

$(document).on("turbolinks:load", function() {
  patchLikeLinks();
});

// select2
require("select2/dist/css/select2");
import Select2 from "select2";

$.fn.select2.defaults.set("width", "100%");

$(document).on("turbolinks:load", function() {
  $("html").removeClass("uk-modal-page");
  $("html").css("overflow-y", "unset");
  $(".js-searchable").select2();
  const $children = $(".js-searchable-children"); //親の要素を変数
  const original = $children.html(); //オリジナルデータ
  $(".js-searchable-parent").on("select2:select", function() {
    //選択されたvalueを取得
    const val1 = $(this).val();
    console.log(val1);
    //削除された要素をもとに戻すため.html(original)を入れておく
    $children
      .html(original)
      .find("option")
      .each(function() {
        const val2 = $(this).data("child-val"); //data-child_valの値を取得
        console.log(val2);
        //valueと異なるdata-valを持つ要素を削除
        if (val1 != val2) {
          $(this).remove();
        }
      });
    // 地方側のselect要素が未選択の場合、都道府県をdisabledにする
    if ($(this).val() == "") {
      $children.attr("disabled", "disabled");
    } else {
      $children.removeAttr("disabled");
    }
  });
});

// tubolinksがloadしたタイミングで、アドセンスscriptを走らせる
$(document).on("turbolinks:load", function() {
  var ads = document.querySelectorAll(".adsbygoogle");
  if (ads.length > 0) {
    ads.forEach(function(ad) {
      if (ad.firstChild) {
        ad.removeChild(ad.firstChild);
      }
    });
    ads.forEach(function() {
      window.adsbygoogle = window.adsbygoogle || [];
      window.adsbygoogle.push({});
    });
  }
});

// target="_blank"のリンクに対して"rel="noopener"を付与する
$(document).on("turbolinks:load", function() {
  Array.from(document.getElementsByTagName("a"))
    .filter(a => a.target == "_blank")
    .forEach(a => a.setAttribute("rel", "noopener"));
});

// dropzone
import Dropzone from 'dropzone'
Dropzone.autoDiscover = false;
window.Dropzone = Dropzone;

// 一番上の名前空間
window.WU = window.WU || {};

// admin
window.WU.Admin = window.WU.Admin || {};

// admin/guidance_videos
window.WU.Admin.GuidanceVideos = window.WU.Admin.GuidanceVideos || {};
// admin/guidance_videos/new
import AdminGuidanceVideosNew from "./javascripts/views/admin/guidance_videos/new";
window.WU.Admin.GuidanceVideos.New = window.WU.Admin.GuidanceVideos.New || {};
window.WU.Admin.GuidanceVideos.New = AdminGuidanceVideosNew;
// admin/guidance_videos/edit
import AdminGuidanceVideosEdit from "./javascripts/views/admin/guidance_videos/edit";
window.WU.Admin.GuidanceVideos.Edit = window.WU.Admin.GuidanceVideos.Edit || {};
window.WU.Admin.GuidanceVideos.Edit = AdminGuidanceVideosEdit;

// admin/user_videos
window.WU.Admin.UserVideos = window.WU.Admin.UserVideos || {};
// admin/user_videos/new
import AdminUserVideosNew from "./javascripts/views/admin/user_videos/new";
window.WU.Admin.UserVideos.New = window.WU.Admin.UserVideos.New || {};
window.WU.Admin.UserVideos.New = AdminUserVideosNew;
// admin/user_videos/edit
import AdminUserVideosEdit from "./javascripts/views/admin/user_videos/edit";
window.WU.Admin.UserVideos.Edit = window.WU.Admin.UserVideos.Edit || {};
window.WU.Admin.UserVideos.Edit = AdminUserVideosEdit;

// mediator
window.WU.Mediator = window.WU.Mediator || {};

// mediator/guidance_videos
window.WU.Mediator.GuidanceVideos = window.WU.Mediator.GuidanceVideos || {};
// mediator/guidance_videos/index
import MediatorGuidanceVideosIndex from './javascripts/views/mediator/guidance_videos/index'
window.WU.Mediator.GuidanceVideos.Index = window.WU.Mediator.GuidanceVideos.Index || {};
window.WU.Mediator.GuidanceVideos.Index = MediatorGuidanceVideosIndex;
// mediator/guidance_videos/show
import MediatorGuidanceVideosShow from './javascripts/views/mediator/guidance_videos/show'
window.WU.Mediator.GuidanceVideos.Show = window.WU.Mediator.GuidanceVideos.Show || {};
window.WU.Mediator.GuidanceVideos.Show = MediatorGuidanceVideosShow;

// 名前空間なし

// free_plan_payments
window.WU.FreePlanPayments = window.WU.FreePlanPayments || {};
// free_plan_payments/confirmation
import FreePlanPaymentsConfirmation from './javascripts/views/free_plan_payments/confirmation'
window.WU.FreePlanPayments.Confirmation = window.WU.FreePlanPayments.Confirmation || {};
window.WU.FreePlanPayments.Confirmation = FreePlanPaymentsConfirmation;

// message_groups
window.WU.MessageGroups = window.WU.MessageGroups || {};
// message_groups/show
import MessageGroupsShow from './javascripts/views/message_groups/show'
window.WU.MessageGroups.Show = window.WU.MessageGroups.Show || {};
window.WU.MessageGroups.Show = MessageGroupsShow;

// tour_inquiries
window.WU.TourInquiries = window.WU.TourInquiries || {};
// tour_inquiries/new
import TourInquiriesNew from './javascripts/views/tour_inquiries/new'
window.WU.TourInquiries.New = window.WU.TourInquiries.New || {};
window.WU.TourInquiries.New = TourInquiriesNew;

// user_videos
window.WU.UserVideos = window.WU.UserVideos || {};
// user_videos/index
import UserVideosIndex from './javascripts/views/user_videos/index'
window.WU.UserVideos.Index = window.WU.UserVideos.Index || {};
window.WU.UserVideos.Index = UserVideosIndex;
// user_videos/show
import UserVideosShow from './javascripts/views/user_videos/show'
window.WU.UserVideos.Show = window.WU.UserVideos.Show || {};
window.WU.UserVideos.Show = UserVideosShow;

// tours
window.WU.Tours = window.WU.Tours || {};
// tours/index
import ToursIndex from './javascripts/views/tours/index'
window.WU.Tours.Index = window.WU.Tours.Index || {};
window.WU.Tours.Index = ToursIndex;

