import Splide from '@splidejs/splide';

export default () => {
  // Heroのスライダー
  const heroSlider = new Splide('.js-user-videos-hero-slider', {
    autoplay: true,
    perPage: 2,
    breakpoints: {
      640: {
        perPage: 1,
      }
    },
    perMove: 1,
    classes: {
      // Add classes for arrows.
      prev  : 'splide__arrow--prev hidden sm:flex',
      next  : 'splide__arrow--next hidden sm:flex',
      // Add classes for pagination.
      pagination: 'splide__pagination hidden lg:inline-flex', // container
    },
  }).mount();

  // 地域ごとのユーザー動画のスライダー
  const regionSliders = [];
  document.querySelectorAll('.js-user-videos-region-slider').forEach((slider) => {
    let regionSlider = new Splide(slider, {
      perPage: 4,
      breakpoints: {
        1024: {
          perPage: 3,
        },
        768: {
          perPage: 2,
        },
        640: {
          perPage: 1,
        }
      },
      perMove: 1,
      padding: {
        left: 0,
        right: '7rem',
      },
      classes: {
        // Add classes for arrows.
        prev: 'splide__arrow--prev hidden sm:flex',
        next: 'splide__arrow--next hidden sm:flex',
        // Add classes for pagination.
        pagination: 'splide__pagination hidden', // container
      },
    }).mount();

    regionSliders.push(regionSlider);
  });
};
