import flatpickr from 'flatpickr';

export default () => {
  flatpickr(".js-tour-inquiry-select-date",  {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    minDate: new Date().fp_incr(+2),
  });

  flatpickr(".js-tour-inquiry-select-time",  {
    enableTime: true,
    noCalendar: true,
    dateFormat: "h:i K",
  });
};
