import Plyr from "plyr";

export default () => {
  $('.js-player').each((index, element) => {
    const player = new Plyr(element);
    const userVideoId = $('.js-user-video-id').data('user-video-id');
    let isPlayed = false;

    player.on('play', () => {
      if (isPlayed) return;

      $.ajax({
        type: 'PUT',
        url: `/user_videos/${userVideoId}/increment_play_count`
      })

      isPlayed = true
    });
  })
};
