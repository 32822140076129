class LikeRequester {
  constructor($root) {
    this.$root = $root
    this.isLiked = this.$root.hasClass('uk-icon-heart-active')
    this.like_url = this.$root.data('like-url')
    this.unlike_url = this.$root.data('unlike-url')
    this.handleEvent()
  }

  like() {
    return $.ajax({
      type: 'GET',
      url: this.like_url,
    })
    .then(
    function(data, textStatus, jqXHR) {
      this.$root.removeClass('uk-icon-heart').addClass('uk-icon-heart-active')
      this.isLiked = true
    }.bind(this))
    .catch(function(e){
      throw new Error(e);
    })
  }

  unlike() {
    return $.ajax({
      type: 'GET',
      url: this.unlike_url,
    })
    .then(
      function(data, textStatus, jqXHR) {
        this.$root.removeClass('uk-icon-heart-active').addClass('uk-icon-heart')
        this.isLiked = false
      }.bind(this))
    .catch(function(e){
      throw new Error(e);
    })
  }

  handleEvent() {
    this.$root.on('click', function(e){
      e.preventDefault()

      if(this.isLiked) {
        this.unlike()
      } else {
        this.like()
      }
    }.bind(this))
  }
}

export default function(root_class_name, url) {
  $(function() {
    const $like_links = $('.like_link')
    if($like_links.length === 0) return

    const num = $like_links.length
    let i = 0

    for(i; i < num; i++) {
      new LikeRequester($($like_links[i]))
    }
  })
}
