export default function(locale) {
  const form = document.querySelector('.js-stripe-payment-intent-form')
  if (form) {
    const publicKey = process.env.STRIPE_PUBLISHABLE_KEY;
    const formLocale = locale || 'en'
    const stripe = Stripe(publicKey, { locale: formLocale});
    const elements = stripe.elements();
    const style = {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: '#CFD7E0'
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };
    const card = elements.create('cardNumber', { style: style });
    card.mount('#card-element');

    const cardExpiry = elements.create('cardExpiry', { style: style });
    cardExpiry.mount('#card-expiry-element');

    const cardCvc = elements.create('cardCvc', { style: style });
    cardCvc.mount('#card-cvc-element');

    const submitBtn = form.querySelector('.js-stripe-payment-intent-form-submit');

    card.addEventListener('change', (event) => {
      const displayError = document.getElementById("card-errors");
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    })

    form.addEventListener('submit', (event) => {
      submitBtn.disabled = true;
      submitBtn.classList.add('disabled:opacity-50');
      event.preventDefault();
      let data = {
        payment_method: {
          card: card
        }
      };

      stripe.confirmCardPayment(form.dataset.paymentIntentId, data).then((result) => {
        if (result.error) {
          submitBtn.disabled = false;
          submitBtn.classList.remove('disabled:opacity-50');
          const errorElement = document.getElementById("card-errors");
          errorElement.textContent = result.error.message;
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            form.submit();
          }
        }
      })
    })
  }
};
