export default () => {
  const $messageInput = $('.js-message-input');
  const $submitBtn = $('.js-message-submit');

  $submitBtn.prop('disabled', true);

  $messageInput.on('keyup', (e) => {
    if ($(e.currentTarget).val().trim()) {
      $submitBtn.prop('disabled', false);
    } else {
      $submitBtn.prop('disabled', true);
    }
  });
};
