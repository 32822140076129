import Splide from '@splidejs/splide';

export default () => {

  // 日程ごとのツアーカードのスライダー
  const regionSliders = [];
  document.querySelectorAll('.js-tours-slider').forEach((slider) => {
    let regionSlider = new Splide(slider, {
      perPage: 4,
      breakpoints: {
        1024: {
          perPage: 3,
        },
        768: {
          perPage: 2,
        },
        640: {
          perPage: 1,
        }
      },
      perMove: 1,
      padding: {
        left: 0,
        right: '7rem',
      },
      classes: {
        // Add classes for arrows.
        prev: 'splide__arrow--prev hidden sm:flex',
        next: 'splide__arrow--next hidden sm:flex',
        // Add classes for pagination.
        pagination: 'splide__pagination hidden', // container
      },
    }).mount();

    regionSliders.push(regionSlider);
  });
};
