import "uppy/dist/uppy.min.css";
import {Core, Dashboard, AwsS3} from "uppy";

function fileUpload() {
  const uploadByUppy = (fileInput) => {
    const formGroup = fileInput.parentNode,
      videoPreview = formGroup.parentNode.nextElementSibling,
      hiddenInput = formGroup.querySelector(".js-upload-data"),
      directory = fileInput.dataset.directory

    // remove our file input in favour of Uppy's
    formGroup.removeChild(fileInput);

    const uppy = Core({
      id: fileInput.id,
      autoProceed: true,
      restrictions: {
        allowedFileTypes: fileInput.accept.split(","),
        maxFileSize: 1024 * 1024 * 1024, // 1G bytes
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
      }, })
      .use(Dashboard, {
        trigger: formGroup,
        closeModalOnClickOutside: true,
        closeAfterFinish: true,
      })
      .use(AwsS3, {
        // uppyによりcompanionUrlに設定したpathの末尾に /s3/params が自動的に付くようになっているので注意
        // 複数のディレクトリに出し分けする際、POST用の署名付きURLをGETするためのエンドポイントも分ける必要があるため動的に切り替えている
        companionUrl: `/presign/${directory}`,
      });

    uppy.on("upload-success", (file, response) => {
      // preview
      videoPreview.src = URL.createObjectURL(file.data);
      videoPreview.classList.remove("hidden");

      const cacheFilePattern = new RegExp("^" + directory + "_cache\/(.+)");
      // construct uploaded file data in the format that Shrine expects
      const uploadedFileData = {
        id: file.meta["key"].match(cacheFilePattern)[1], // object key without prefix
        storage: "cache",
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        },
      };

      // set hidden field value to the uploaded file data so that it's submitted
      // with the form as the attachment
      hiddenInput.value = JSON.stringify(uploadedFileData);
    });
  }

  document.querySelectorAll('.js-upload-file').forEach(fileInput => {
    uploadByUppy(fileInput)
  });
}

export default fileUpload;
