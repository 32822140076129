import consumer from "./consumer"

$(document).on('turbolinks:load', function() {
  const messageGroupId = $('.js-message-group-id').data('message-group-id')
  if (!messageGroupId) return;

  consumer.subscriptions.create({'channel': "RoomChannel", 'message_group_id': messageGroupId}, {
    connected() {
      // Called when the subscription is ready for use on the server
    },
    disconnected() {
      // Called when the subscription has been terminated by the server
    },
    received(data) {
      $('#chat_messages').append(data['message']);

      // 送信した側の表示だけ変わるようにしている
      const fromUserId = data['from_user_id'];
      const inputIdName = '#js-message-input-' + fromUserId;
      const submitIdName = '#js-message-submit-' + fromUserId;
      $(inputIdName).val('');
      $(submitIdName).prop('disabled', true);
    }
  });
});
